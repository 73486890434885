
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";
import * as _ from "lodash";
import SteamItem from "@/components/steam/SteamItem.vue";
import CircleLoading from "@/components/loading/CircleLoading.vue";

@Component({
  components: {
    AdminTable,
    CircleLoading,
    SteamItem
  }
})
export default class AdminSteamItemPriceRule extends Vue {
  creationDialog = false;
  selected: any[] = [];

  itemsDialogsShow = false;
  creation: any = {
    text: {
      label: "text",
      value: "",
      required: true,
      type: "string"
    },
    priceRange: {
      label: "priceRange",
      value: "",
      required: false,
      type: "string"
    },
    mode: {
      label: "mode",
      value: "CONTAIN",
      required: true,
      type: "string",
      enum: ["CONTAIN", "START", "END", "APP_ID_EACH"]
    },
    countPerDay: {
      label: "countPerDay",
      value: 0,
      required: true,
      type: "number"
    },
    countPerWeek: {
      label: "countPerWeek",
      value: 0,
      required: true,
      type: "number"
    },
    countPerMonth: {
      label: "countPerMonth",
      value: 0,
      required: true,
      type: "number"
    }
  };

  columns = [
    {
      name: "_id",
      required: true,
      label: "_id",
      field: "_id",
      align: "left",
      type: "string",
      classes: "ellipsis",
      style: "max-width: 15px"
    },
    {
      name: "text",
      required: true,
      label: "text",
      field: "text",
      align: "left",
      type: "string",
      editable: true
    },
    {
      name: "priceRange",
      required: false,
      label: "priceRange",
      field: "priceRange",
      align: "left",
      type: "string",
      editable: true
    },
    {
      name: "mode",
      required: true,
      label: "mode",
      field: "mode",
      align: "left",
      type: "string",
      enum: ["CONTAIN", "START", "END", "APP_ID_EACH"],
      editable: true
    },
    {
      name: "countPerDay",
      align: "center",
      label: "countPerDay",
      field: "countPerDay",
      editable: true,
      type: "number"
    },
    {
      name: "countPerWeek",
      align: "center",
      label: "countPerWeek",
      field: "countPerWeek",
      editable: true,
      type: "number"
    },
    {
      name: "countPerMonth",
      align: "center",
      label: "countPerMonth",
      field: "countPerMonth",
      editable: true,
      type: "number"
    }
  ];

  created() {}

  get lastSelected() {
    return _.last(this.selected) || null;
  }

  get creationVariables() {
    return Object.keys(this.creation).reduce((acc, c) => {
      acc[c] = this.creation[c].value;
      if (
        this.creation[c].type == "number" &&
        acc[c] !== undefined &&
        acc[c] !== ""
      ) {
        acc[c] = Number(acc[c]);
      }

      return acc;
    }, {});
  }

  creationReset() {
    Object.keys(this.creation).forEach(key => {
      this.creation[key].value = undefined;
    });
  }
}
