var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AdminTable',{attrs:{"columns":_vm.columns,"title":`Коллекция items-counter-rules`,"model-name":"itemsCounterRuleModel"},on:{"update:selected":value => (_vm.selected = value)},scopedSlots:_vm._u([{key:"top-right",fn:function({ query }){return [_c('q-btn',{attrs:{"label":"Создать","color":"primary","push":"","size":"md","rounded":""},on:{"click":function($event){_vm.creationDialog = true}}}),_c('q-dialog',{model:{value:(_vm.creationDialog),callback:function ($$v) {_vm.creationDialog=$$v},expression:"creationDialog"}},[_c('q-card',{staticStyle:{"min-width":"500px"}},[_c('q-card-section',{staticClass:"row items-center q-pb-none"},[_c('div',{staticClass:"text-h6"},[_vm._v("Создание правила")]),_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"close","flat":"","round":"","dense":""}})],1),_c('q-card-section',[_c('ApolloMutation',{attrs:{"mutation":gql => gql`
                mutation createItemsCounterRule(
                  $text: String!
                  $priceRange: String
                  $mode: String!
                  $countPerDay: Float
                  $countPerWeek: Float
                  $countPerMonth: Float
                ) {
                  createItemsCounterRule(
                    text: $text
                    priceRange: $priceRange
                    mode: $mode
                    countPerDay: $countPerDay
                    countPerWeek: $countPerWeek
                    countPerMonth: $countPerMonth
                  )
                }
              `,"variables":_vm.creationVariables},on:{"error":error =>
                _vm.$q.notify({
                  message: error.message,
                  multiLine: true,
                  type: 'negative'
                }),"done":() => {
                query.refetch();
                _vm.creationReset();
              }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading, error }){return [_c('q-form',{on:{"submit":function($event){return mutate()}}},[_vm._l((_vm.creation),function(col,key){return [(col.enum)?_c('q-select',{attrs:{"options":col.enum,"map-options":"","emit-value":"","label":col.label},model:{value:(col.value),callback:function ($$v) {_vm.$set(col, "value", $$v)},expression:"col.value"}}):_c('q-input',{attrs:{"label":col.label},model:{value:(col.value),callback:function ($$v) {_vm.$set(col, "value", $$v)},expression:"col.value"}})]}),_c('q-btn',{staticClass:"full-width q-mt-md",attrs:{"label":"Создать","loading":loading,"color":"primary","size":"lg","push":"","type":"submit"}})],2)]}}],null,true)})],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }